import React from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import spotifyButton from '../images/listen-on-spotify-button.png'
import richTextOptions from '../shared/richTextOptions'
import { getContentfulUrl, rightBack } from '../shared/utilities'

const Podcast = ({ pageContext, location }) => {
  const { id, title, author, url, podcastImage, summary } = pageContext
  const imgQuery = '?w=300'
  const imgUrl = getContentfulUrl(podcastImage) + imgQuery
  const spotifyUrl = 'https://open.spotify.com/embed/show/' + rightBack(url, 'show/')

  return (
    <Layout editPageId={id} path={location.pathname}>
      <SEO title={title} />
      <div className="container podcast text-content">
        <Link to="/resources/podcasts" className="back-link">
          &larr; Podcasts
        </Link>

        <div className="row">
          <div className="col-md-7" style={{ paddingLeft: '20px' }}>
            <h1>{title}</h1>
            <h4>{author}</h4>
            {documentToReactComponents(summary.json, richTextOptions)}
            <p />
            <a href={url}>
              <img height="50px" src={spotifyButton} alt={title} />
            </a>
          </div>
          <div className="col-md-5">
            <img src={imgUrl} alt={title} target="_blank" rel="noopener noreferrer" />
            <p />
            <iframe
              title="spotify"
              src={spotifyUrl}
              width="300"
              height="300"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Podcast

Podcast.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}
